import React from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
//Assets
import HeroImage from '../../assets/hero/hero-image.png';
//Components
import Button from '../ui-components/button/button';

const hero = () => (
  <div className="hero" id="hero">
    <div className="wrapper">
      <Row>
        <Col md={12} lg={6}>
          <div className="hero-info">
            {/* <h1 className="weight800 font60" style={{ color: '#FFE600' }}>RTJ</h1> */}
            <h1 className="weight800 font60">
            Your Partner in Custom Software Excellence
            </h1>
            <p className="font12">
            Unlock your business potential with our tailored software solutions. 
            From innovative startups to established enterprises, our skilled developers bring your ideas to life 
            with cutting-edge technology and personalized service. Let us drive your success.
            </p>
            <Button label="SEND MESSAGE" target={"contact"} />
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className="hero-image">
            <img src={HeroImage} alt="hero" />
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default hero;
